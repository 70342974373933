import React from 'react';
import { Link,NavLink } from 'react-router-dom'
import {  GridItem,Button,useColorModeValue,
  Grid
} from '@chakra-ui/react';

import { BiHomeCircle,BiSortAlt2 ,BiCoinStack,BiSearchAlt2} from 'react-icons/bi';


const Footer = () => {
  return (
 <>

 
<Grid bg={useColorModeValue('gray.100', 'gray.900')} py={2} templateColumns='repeat(4, 1fr)' pos="fixed" bottom={'0'} left={'0'}  textAlign={'center'}  w={'full'}gap={6}>
  <GridItem w='100%' h='10'  >
   <NavLink to="/" className={(navData) => (navData.isActive ? 'active_button' : 'active_button_none')}
 >
 <Button colorScheme="purple" p={'0'} w={'10'} h={'10'} 
borderRadius={'full'}  >
        <BiHomeCircle size={'20'}/> 
 </Button>
 </NavLink> 
  </GridItem>

  <GridItem w='100%' h='10'  >
  <NavLink to="/exchanges" className={(navData) => (navData.isActive ? 'active_button' : 'active_button_none')}>

 <Button colorScheme="purple" p={'0'} w={'10'} h={'10'} 
borderRadius={'full'}  >
        <BiSortAlt2 size={'20'}/> 
 </Button>
 </NavLink> 
  </GridItem>

  <GridItem w='100%' h='10'  >
  <NavLink to="/coins" className={(navData) => (navData.isActive ? 'active_button' : 'active_button_none')}>

 <Button colorScheme="purple" p={'0'} w={'10'} h={'10'} 
borderRadius={'full'}  >
        <BiCoinStack size={'20'}/> 
 </Button>
 </NavLink> 
     </GridItem>
  <GridItem w='100%' h='10'  >
  <NavLink to="/search" className={(navData) => (navData.isActive ? 'active_button' : 'active_button_none')}>

 <Button colorScheme="purple" p={'0'} w={'10'} h={'10'} 
borderRadius={'full'}  >
        <BiSearchAlt2 size={'20'}/> 
 </Button>
 </NavLink> 
     </GridItem>
</Grid>
 </>
  )
}

export default Footer