import React from 'react'
import {
    Spinner,Flex,Button,Progress
  } from "@chakra-ui/react";

const Loader = () => {
  return (
    <>

<Flex h="80vh" justifyContent="center" alignItems="center">
    <div textAlign="center" >

    <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl' margin={'auto'}
/>
<div  > Loading ...</div>

    </div>

    </Flex>

  

</>
  )
}

export default Loader