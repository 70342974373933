import { Fragment} from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./assets/css/style.css";
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Coins from "./pages/Coins";
import Exchanges from "./pages/Exchanges";
import CoinDetail from "./pages/CoinDetail";
import { ChakraProvider ,extendTheme} from '@chakra-ui/react';
import { BottomNavigationStyleConfig as BottomNavigation } from 'chakra-ui-bottom-navigation';
import Search from "./pages/Search";
import {Helmet} from "react-helmet";

const a = 200;

const Test = () => <h1>Test {a}</h1>;


function App(){

  const theme = extendTheme({
    components: {
      BottomNavigation,
    },
  });

return (
  <ChakraProvider theme={theme} >

<Router>
<Header/>
<Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
<Routes>
  <Route path="/" element={<Home/>}/>
  <Route path="/about" element={<About/>}/>
  <Route path="/exchanges" element={<Exchanges/>}/>
  <Route path="/coins" element={<Coins/>}/>
  <Route path="/coins/:id" element={<CoinDetail/>}/>
  
  <Route path="/search" element={<Search/>}/>
  <Route path="*" element={<NotFound/>}/>
</Routes>

<Footer/>

</Router>
</ChakraProvider>

);
}

export default App