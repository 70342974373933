import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { server } from '..';
import axios from 'axios';
import Loader from '../components/Loader';

import {
  Container,
  Heading,
  HStack,
  Image,Button,
  Text,Flex,Avatar,Box,Badge,StatHelpText,StatArrow,RadioGroup,Radio,
  VStack,SimpleGrid,StatLabel,Stat,StatNumber ,useColorModeValue,SkeletonCircle,SkeletonText,Skeleton,Stack,Grid
} from "@chakra-ui/react";

const Home = () => {

  const [exchanges, setExchanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("inr");

  const currencySymbol = 
  currency === "inr" ? "₹" : currency === "eur" ?  "€" : "$" ;

  useEffect(()=>{
const fetchExchanges = async()=>{
  const {data} = await axios.get(`${server}/coins/markets?vs_currency=${currency}&order=market_cap_desc&per_page=12`);
  setExchanges(data);

  setLoading(false);
  console.log(data);
}

fetchExchanges();
}, [currency]);
 
  return (
   
    <div class="ion-page" id="main-content" >

{/* <RadioGroup py={'5'} value={currency} onChange={setCurrency}   px={{ base: 2, md: 4 }} >
      <Stack direction='row'>
        <Radio value='inr'>INR</Radio>
        <Radio value='usd'>USD</Radio>
        <Radio value='eur'>EUR</Radio>
      </Stack>
    </RadioGroup> */}

{loading ? (

<Loader/>

      ) : (
        <>
        <SimpleGrid columns={{ base: 1, md: 3 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
      {

exchanges.map(i=>(
  <Link to={'coins/'+i.id}>

<Stat
      px={{ base: 2, md: 2 }}
      py={'5'}
      shadow={'md'}
      border={'1px solid'}
      borderColor={('gray.800', 'gray.500')}
      rounded={'lg'}>
 
<Flex>
  <Avatar src={i.image} />
  <Box ml='3'>
    <Text fontWeight='bold'>
    {i.name}
      
    </Text>
    <Text fontSize='sm'>
    <StatHelpText>
  
<StatArrow type={
  ( i.market_cap_change_percentage_24h  ) < 0 ? "decrease" : "increase"
} />

      {i.market_cap_change_percentage_24h} 

    </StatHelpText>
    </Text>
  </Box>
  <Box ml='3'  textAlign={'right'} w="max-content" marginLeft={'auto'} >
    <Text fontWeight='bold'>
    {i.symbol}
    </Text>
    <Text fontSize='sm'> {currencySymbol} {i.current_price} </Text>
  </Box>
</Flex>

    </Stat>


  </Link>
)) }
   
</SimpleGrid>


<Flex h="10vh" justifyContent="center" alignItems="center">
  <Link to={'coins'}>
  <Button
      px={4}
        fontSize={'sm'}
        colorScheme="purple"        color={'white'}
      
        _hover={{
          bg: 'blue.500',
        }}
        _focus={{
          bg: 'blue.500',
        }}>
         View All
      </Button>
      </Link>  
    </Flex>


        </>
      )}
      
    </div>
    
    
  )
  
};

 const HomeSkeleton = () => {
  <SimpleGrid columns={{ base: 1, md: 3 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
  
  <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
    
  
        <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
  
        <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
  
  </SimpleGrid>
  
  };


  
export default Home;
