import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
about

    </>
  )
}

export default About