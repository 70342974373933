import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { server } from '..';
import axios from 'axios';
import Loader from '../components/Loader';

import {
  Container,
  Heading,
  HStack,
  Image,Button,
  Text,Flex,Avatar,Box,Badge,StatHelpText,StatArrow,RadioGroup,Radio,
  VStack,SimpleGrid,StatLabel,Stat,StatNumber ,useColorModeValue,SkeletonCircle,SkeletonText,Skeleton,Stack,Grid
} from "@chakra-ui/react";


const Exchanges = () => {

  const [exchanges, setExchanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("inr");

  const currencySymbol = 
  currency === "inr" ? "₹" : currency === "eur" ?  "€" : "$" ;

  useEffect(()=>{
const fetchExchanges = async()=>{
  const {data} = await axios.get(`${server}/coins/markets?vs_currency=${currency}&order=market_cap_desc&per_page=102`);
  setExchanges(data);

  setLoading(false);
  console.log(data);
}

fetchExchanges();
}, [currency]);
 
  return (
   
    <div class="ion-page" id="main-content" >

{/* <RadioGroup py={'5'} value={currency} onChange={setCurrency}   px={{ base: 2, md: 4 }} >
      <Stack direction='row'>
        <Radio value='inr'>INR</Radio>
        <Radio value='usd'>USD</Radio>
        <Radio value='eur'>EUR</Radio>
      </Stack>
    </RadioGroup> */}

{loading ? (

<Loader/>

      ) : (
        <>
        <SimpleGrid columns={{ base: 1, md: 3 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
      {

exchanges.map(i=>(
  <Link to={'/coins/'+i.id}>

<Stat
      px={{ base: 2, md: 2 }}
      py={'5'}
      shadow={'md'}
      border={'1px solid'}
      borderColor={('gray.800', 'gray.500')}
      rounded={'lg'}>
 
<Flex>
  <Avatar src={i.image} />
  <Box ml='3'>
  <Text fontSize='sm'>
    <StatHelpText>
  
<StatArrow type="decrease" />

{i.low_24h }

    </StatHelpText>
    </Text>
    <Text fontWeight='bold'>
    {i.name}
      
    </Text>

  </Box>
  <Box ml='3'  textAlign={'right'} w="max-content" marginLeft={'auto'} >

    <Text fontSize='sm'>
    <StatHelpText>
  
<StatArrow type="increase" />

{i.high_24h} 

    </StatHelpText>
    </Text>

    <Text fontWeight='bold'>
    {i.symbol}
    </Text>
   

  </Box>
</Flex>

    </Stat>


  </Link>
)) }
   
</SimpleGrid>




        </>
      )}
      
    </div>
    
    
  )
  
};

 const HomeSkeleton = () => {
  <SimpleGrid columns={{ base: 1, md: 3 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
  
  <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
    
  
        <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
  
        <Stat
        px={{ base: 2, md: 2 }}
        py={'5'}
        shadow={'md'}
        border={'1px solid'}
        borderColor={('gray.800', 'gray.500')}
        rounded={'lg'}>
  
  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
  <SkeletonCircle size='60px' width="max-content"  />
  
    <Skeleton height='60px' width={'170%'} ml="-80%" />
  
  </Grid>
        </Stat>
  
  </SimpleGrid>
  
  };

export default Exchanges