import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { server } from '..';
import axios from 'axios';
import Loader from '../components/Loader';
import ErrorComponent from '../components/ErrorComponent';
import {Helmet} from "react-helmet";

import {
  Container,
  Heading,
  Image,
  Text,Flex,Avatar,Box,Badge,StatHelpText,StatArrow,RadioGroup,Radio,Button,HStack,
  VStack,SimpleGrid,StatLabel,Stat,StatNumber ,useColorModeValue,SkeletonCircle,SkeletonText,Skeleton,Stack,Grid
} from "@chakra-ui/react";

const Coins = () => {

    const [exchanges, setExchanges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState("inr");
    const [page, setPage] = useState(1);
    const [error, setError] = useState(false);

    const currencySymbol = 
    currency === "inr" ? "₹" : currency === "eur" ?  "€" : "$" ;
  
    
  const changePage = (page) => {
    setPage(page);
    setLoading(true);
    window.scrollTo(0, 0);
  };

  const btns = new Array(130).fill(1);

    useEffect(()=>{
  const fetchExchanges = async()=>{

    try {
        const { data } = await axios.get(
          `${server}/coins/markets?vs_currency=${currency}&page=${page}`
        );
        setExchanges(data);
        console.log(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
  
  }
  
  fetchExchanges();
  }, [currency,page]);

  if (error) return <ErrorComponent message={"Error While Fetching Coins"} />;


  return (

    
    <div class="ion-page" id="main-content" >

<Helmet>
                <meta charSet="utf-8" />
                <title>My coins</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            
<RadioGroup py={'5'} value={currency} onChange={setCurrency}   px={{ base: 2, md: 4 }} >
      <Stack direction='row'>
        <Radio value='inr'>Inr</Radio>
        <Radio value='usd'>USD</Radio>
        <Radio value='eur'>EUR</Radio>
      </Stack>
    </RadioGroup>

{loading ? (

<Loader/>

      ) : (
        <>
        <SimpleGrid columns={{ base: 1, md: 3 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
      {

exchanges.map(i=>(
  <div>
  <Link to={'/coins/'+i.id}>

<Stat
      px={{ base: 2, md: 2 }}
      py={'5'}
      shadow={'md'}
      border={'1px solid'}
      borderColor={('gray.800', 'gray.500')}
      rounded={'lg'}>
 
<Flex>
  <Avatar src={i.image} />
  <Box ml='3'>
    <Text fontWeight='bold'>
    {i.name}
      
    </Text>
    <Text fontSize='sm'>
    <StatHelpText>
  
<StatArrow type={
  ( i.market_cap_change_percentage_24h  ) < 0 ? "decrease" : "increase"
} />

      {i.market_cap_change_percentage_24h} 

    </StatHelpText>
    </Text>
  </Box>
  <Box ml='3'  textAlign={'right'} w="max-content" marginLeft={'auto'} >
    <Text fontWeight='bold'>
    {i.symbol}
    </Text>
    <Text fontSize='sm'> {currencySymbol} {i.current_price} </Text>
  </Box>
</Flex>

    </Stat>
</Link>

  </div>
)) }
   
</SimpleGrid>


<HStack px="20px" overflow={'auto'}> 

{btns.map((item, index) => (
           
           <Button
           key={index}
           bgColor={"blackAlpha.900"}
           color={"white"}  disabled={page === index} 
           onClick={() => changePage(index)}
         >
           {index + 1}
         </Button>
              
            ))} 

</HStack>

        </>
      )}
  
    </div>

  )
};
export default Coins;