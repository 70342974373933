import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar';
import ColorModeSwitcher from '../ColorModeSwitcher';

import {  Drawer,Text,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,Button,Input,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

import { BiMenuAltLeft } from 'react-icons/bi';

const Header = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <Fragment>


<Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} pos="fixed" w="full" top="0" left="0" zIndex={'9'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box><b><Link to={'/'}> CRYPTO </Link></b></Box>

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>

           <ColorModeSwitcher/>

              <Menu>
              <Button colorScheme="purple" p={'0'} w={'10'} h={'10'} 
borderRadius={'full'}  onClick={onOpen}>
        <BiMenuAltLeft size={'20'}/>
 </Button>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>



      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader> Menu</DrawerHeader>

          <DrawerBody>

            
          <Text my="4" >
  <Link to="/" onClick={onClose}>Home
</Link>
</Text>


<Text my="4">
<Link to="/exchanges" onClick={onClose}>Exchanges Coin
</Link>
</Text>

<Text my="4">
<Link to="/coins" onClick={onClose}>All Coins
</Link>
</Text>


<Text my="4">
<Link to="/search" onClick={onClose}>Search Coin
</Link>
</Text>

          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      
    </Fragment>
  
  )
}

export default Header