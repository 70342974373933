
import React,{useEffect,useState} from 'react';
import { Link,useParams } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { server } from '..';
import axios from 'axios';
import Loader from '../components/Loader';
import ErrorComponent from '../components/ErrorComponent';
import Chart from '../components/Chart';
import {
    Container,
    Heading,
    Image,
    Text,Flex,Avatar,Box,Badge,StatHelpText,StatArrow,RadioGroup,Radio,Button,HStack,Progress,
    VStack,SimpleGrid,StatLabel,Stat,StatNumber ,useColorModeValue,SkeletonCircle,SkeletonText,Skeleton,Stack,Grid
  } from "@chakra-ui/react";
  import {Helmet} from "react-helmet";


const CoinDetail = () => {

    
    const params =  useParams();

    
    const [detail, setDetail] = useState([]);
    const [exchanges, setExchanges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currency, setCurrency] = useState("inr");
    const [days, setDays] = useState("24h");
    const [chartArray, setChartArray] = useState([]);
    const currencySymbol = 
    currency === "inr" ? "₹" : currency === "eur" ?  "€" : "$" ;
  
    const btns = ["24h", "7d", "14d", "30d", "60d", "200d", "1y", "max"];

    const switchChartStats = (key) => {
      switch (key) {
        case "24h":
          setDays("24h");
          setLoading(true);
          break;
        case "7d":
          setDays("7d");
          setLoading(true);
          break;
        case "14d":
          setDays("14d");
          setLoading(true);
          break;
        case "30d":
          setDays("30d");
          setLoading(true);
          break;
        case "60d":
          setDays("60d");
          setLoading(true);
          break;
        case "200d":
          setDays("200d");
          setLoading(true);
          break;
        case "1y":
          setDays("365d");
          setLoading(true);
          break;
        case "max":
          setDays("max");
          setLoading(true);
          break;
  
        default:
          setDays("24h");
          setLoading(true);
          break;
      }
    };

    
    useEffect(()=>{
        const fetchCoins = async()=>{
      
          try {
              const { data } = await axios.get(
                `${server}/coins/${params.id}`
              );

              const { data: chartData } = await axios.get(
                `${server}/coins/${params.id}/market_chart?vs_currency=${currency}&days=${days}`
              );
              setDetail(data);
              setChartArray(chartData.prices);
              setLoading(false);
            } catch (error) {
              setError(true);
              setLoading(false);
            }
        
        }
        
        fetchCoins();
        }, [params.id,currency,days]);
      
        if (error) return <ErrorComponent message={"Error While Fetching Coins"} />;


  return (
    <>
 
            
  <div class="ion-page" id="main-content" >

  <Helmet>
                <meta charSet="utf-8" />
                <title>{`${detail.name}`}   coin</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
<RadioGroup py={'5'} value={currency} onChange={setCurrency}   px={{ base: 2, md: 4 }} >
      <Stack direction='row'>
        <Radio value='inr'>INR</Radio>
        <Radio value='usd'>USD</Radio>
        <Radio value='eur'>EUR</Radio>
      </Stack>
    </RadioGroup>

{loading ? (

<Loader/>

      ) : (
        <>
        <SimpleGrid columns={{ base: 1, md: 1 }}    py={'5'}   px={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 8 }}>
      {


  <div>

<Stat
      px={{ base: 2, md: 2 }}
      py={'5'}
      shadow={'md'}
      border={'1px solid'}
      borderColor={('gray.800', 'gray.500')}
      rounded={'lg'}>
 
<Flex>
  <Avatar src={detail.image.large} />
  <Box ml='3'>
    <Text fontWeight='bold'>
    {detail.name}
    </Text>
    <Text fontSize='sm'>
    <StatHelpText>
  
<StatArrow type={
  ( detail.market_data.price_change_percentage_24h  ) < 0 ? "decrease" : "increase"
} />

      {detail.market_data.price_change_percentage_24h} %

    </StatHelpText>
    </Text>
  </Box>




  <Box ml='3'  textAlign={'right'} w="max-content" marginLeft={'auto'} >
    <Text fontWeight='bold'>
    {detail.symbol}
    </Text>
    <Text fontSize='sm'> {currencySymbol} {detail.market_data.current_price[currency]} </Text>
  </Box>
</Flex>

    </Stat>

    <Box width={"full"} my={"5"} borderWidth={1}>
            <Chart arr={chartArray} currency={currencySymbol} days={days} />
          </Box>

<HStack p={'4'} wrap={'wrap'}>
  {
    btns.map((i)=>(
      <Button  disabled={days === i} 
      key={i}  onClick={() => switchChartStats(i)} my={'4'} >
        {i}
      </Button>
    ))
  }
</HStack>

<Badge fontSize={"2xl"} mx={'1'} my={'3'} bgColor={"blackAlpha.800"} color={'white'}>
  {`#${detail.market_cap_rank}`}
</Badge>


<CustomBar diffN={detail.market_data.high_24h[currency] - detail.market_data.low_24h[currency]} 
highN={detail.market_data.high_24h[currency]} lowN={detail.market_data.low_24h[currency]} high={`${currencySymbol}${detail.market_data.high_24h[currency]}`} low={`${currencySymbol}${detail.market_data.low_24h[currency]}`} change={ ( detail.market_data.high_24h[currency]  ) - ( detail.market_data.low_24h[currency]  ) }/> 


<Box w={"full"} p="4">
              <Item title={"Max Supply"} value={detail.market_data.max_supply} />
              <Item
                title={"Market Cap"}
                value={`${currencySymbol}${detail.market_data.market_cap[currency]}`}
              />
              <Item
                title={"All Time Low"}
                value={`${currencySymbol}${detail.market_data.atl[currency]}`}
              />
              <Item
                title={"All Time High"}
                value={`${currencySymbol}${detail.market_data.ath[currency]}`}
              />
             </Box>
  </div>
 }
   
</SimpleGrid>



        </>
      )}

</div>

    </>
  
  )
}

const Item = ({title,value}) =>(
 <HStack  justifyContent={"space-between"} w={"full"} my={"4"} >
  <Text>
    {title}
  </Text>
  <Text>
    {value}
  </Text>
  </HStack>
);

const CustomBar = ({ high, low,highN,lowN,diffN }) => (
  <VStack w={"full"}>
    <Progress value={Math.round(((highN - lowN) / lowN) * 100)} colorScheme={"teal"} w={"full"} />
    <HStack justifyContent={"space-between"} w={"full"}>
      <Badge children={low} colorScheme={"red"} />
      <Text fontSize={"sm"}>24H Range</Text>
      <Badge children={high} colorScheme={"green"} />
    </HStack>
    <p>
 </p>
  </VStack>
)

;



export default CoinDetail;